function slider () {
    /*========
    Clients slider
    ========*/
    const sliderClients = document.querySelectorAll('.home__clients-slider');

    if(sliderClients.length > 0) {
        //Перебираем каждый слайдер на странице
        sliderClients.forEach(el=> {
            //В каждом слайдере находим слайд и получаем его ширину
            const slides = el.querySelector('.home__clients-slide');
            const slidesW = slides.offsetWidth;

            //Создаем дубли слайда в зависимости от ширины экрана
            let slidesCount = Math.ceil((window.innerWidth / slidesW)) * 2;

            //Добавляем дубли в слайдер, чтобы они заняли ее полностью
            for (let i = 0; i < slidesCount; i++) {
                const clonedSlides = slides.cloneNode(true);

                el.querySelector('.home__clients-inner').appendChild(clonedSlides);
            }
        });

        //Создаем экземпляры слайдеров с небольшой задержкой
        setTimeout(()=> {
            //Слайдер с пролистыванием влево
            let sliderToLeft = new Swiper('#sliderClientsToLeft', {
                slidesPerView: 'auto',
                loop: true,
                centeredSlides: true,
                speed: 15000,
                freeMode: true,
                autoplay: {
                    enabled: true,
                    delay: 1,
                    disableOnInteraction: true,
                },
                allowTouchMove: false,
                pauseOnMouseEnter: false,
                freeModeMomentum: false,
                
                breakpoints: {
                    1: {
                        spaceBetween: 6,
                    },
                    768: {
                        spaceBetween: 20,
                    },
                }
            });
    
            //Слайдер с пролистыванием вправо
            let sliderToRight = new Swiper('#sliderClientsToRight', {
                slidesPerView: 'auto',
                loop: true,
                centeredSlides: true,
                speed: 15000,
                freeMode: true,
                autoplay: {
                    enabled: true,
                    delay: 1,
                    disableOnInteraction: true,
                    reverseDirection: true,
                },
                allowTouchMove: false,
                pauseOnMouseEnter: false,
                freeModeMomentum: false,
                spaceBetween: 20,

                breakpoints: {
                    1: {
                        spaceBetween: 6,
                    },
                    768: {
                        spaceBetween: 20,
                    },
                }
            });
        }, 2000);
    }
}

export default slider;