function counter () {
    const counterElements = document.querySelectorAll('[data-counter="num"]');

    if (counterElements.length > 0) {
        counterElements.forEach(el => {
            const numCounterVal = el.textContent;
            const numCounter = parseInt(numCounterVal.replace(/[^\d]/g, ''));

            let startNum = 0;
            let duration = 1000; // Время анимации в миллисекундах
            let startTime = null;

            function animateCounter(timestamp) {
                if (!startTime) startTime = timestamp;
                const progress = timestamp - startTime;

                startNum = Math.min(Math.floor(progress / (duration / numCounter)), numCounter);
                el.innerHTML = startNum;

                if (progress < duration) {
                    requestAnimationFrame(animateCounter);
                }
            }

            const observerCounter = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        requestAnimationFrame(animateCounter);
                    }
                });
            }, { threshold: [.8] });

            observerCounter.observe(el);
        });
    }
}

export default counter;