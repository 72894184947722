function anchors () {
    /*==========
    Anim scroll to anchors
    ==========*/
    const anchorLinks = document.querySelectorAll('.anchorLink');

    if(anchorLinks.length > 0) {
        let speed = .4;
        const header = document.querySelector('.header');
        const headerH = header.offsetHeight;
        const burger = document.querySelector('.header__burger');

        anchorLinks.forEach(el => {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                if(header.classList.contains('open-nav')) {
                    header.classList.remove('open-nav');
                    burger.classList.remove('active');
                }

                let hash = decodeURIComponent(this.getAttribute('href')).replace('#', ''),
                targetElement = document.getElementById(hash),
                t = targetElement.getBoundingClientRect().top,
                w = window.pageYOffset,
                start = null;

                requestAnimationFrame(step);

                function step(time) {
                if (start === null) start = time;

                let progress = time - start,
                    r = (t < 0 ? Math.max(w - progress / speed, w + t) : Math.min(w + progress / speed, w + t));

                setTimeout(()=> {
                    window.scrollTo(0, r - headerH);
                }, 100);
                // window.scrollTo(0, r);

                if (r != w + t) {
                    requestAnimationFrame(step);
                } else {
                    location.hash = '#' + hash;
                }
                }
            }, false);
        });
    }
}

export default anchors;