import slider from "./modules/slider";
import anim from "./modules/anim";
import counter from "./modules/counter";
import scroll from "./modules/scroll";
import anchors from "./modules/anchors";
import nav from "./modules/nav";
import popup from "./modules/popup";
import forms from "./modules/form";

document.addEventListener('DOMContentLoaded', ()=> {
    slider();
    anim();
    counter();
    scroll();
    anchors();
    nav();
    popup();
    forms();
});