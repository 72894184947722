function popup () {
    const showPopupBtn = document.querySelectorAll('.popup-show');

    if(showPopupBtn.length > 0) {
        const popup = document.querySelector('.popup');
        const closePopupBtn = document.querySelectorAll('.popup-close');

        showPopupBtn.forEach(el=> {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                const targetCat = this.dataset.popupcategory;
                const targetPopup = document.querySelector(`[data-popup="${targetCat}"]`);

                targetPopup.classList.add('show');
            });
        });

        closePopupBtn.forEach(el=> {
            el.addEventListener('click', function() {
                const popup = this.closest('.popup');

                popup.classList.remove('show');
            });
        });

        document.addEventListener('click', function(e) {
            if(e.target && e.target.classList.contains('popup')) {
                e.target.classList.remove('show');
            }
        });
    }
}

export default popup;