function nav () {
    const header = document.querySelector('.header');

    if(header) {
        const burger = document.querySelector('.header__burger');
        
        burger.addEventListener('click', ()=> {
            burger.classList.toggle('active');
            header.classList.toggle('open-nav');
        });
    }
}

export default nav;