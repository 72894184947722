function forms () {
    const form = document.querySelectorAll('.form-consult');

    if(form.length > 0) {
        const input = document.querySelectorAll('.form-input');
        const submitBtns = document.querySelectorAll('.form-submit');

        submitBtns.forEach(el=> {
            el.disabled = true;
        });

        input.forEach(el=> {
            el.classList.add('empty');

            el.addEventListener('blur', function() {
                const parent = this.closest('.form-consult');
                const submitBtns = parent.querySelector('.form-submit');

                //Проверяем все инпуты на кол-во символов внутри
                if(this.dataset.validation) {
                    //Берем из дата-атрибута количество минимальных символов
                    const minimalLength = this.dataset.validation;

                    if(this.value.length < minimalLength) {
                        this.classList.add('error');
                        this.classList.add('empty');
                    } else {
                        this.classList.remove('error');
                        this.classList.remove('empty');
                    }
                }


                const nearInput = parent.querySelectorAll('.form-input');

                //Создаем флаг для проверки
                let isEmpty = false;
        
                //Проходим по всем инпутам и если есть пустой инпут, меняем статус флага на true
                nearInput.forEach( el => {
                    if(el.classList.contains('empty') || el.classList.contains('error')) isEmpty = true;
                });

                //Если флаг = true отключаем кнопку отправки, иначе включаем
                isEmpty ? submitBtns.disabled = true : submitBtns.disabled = false;
            });

            //Создаем маску для инпута телефона
            el.addEventListener('input', function () {
                if(this.dataset.input === 'tel') {
                    this.value = this.value.replace(/[^0-9+]/g, '');
                }
            })
        });


        //Находим форму и значение атрибута action для ajax
        form.forEach(el=> {
            const action = el.getAttribute('action');

            el.addEventListener('submit', function(e) {
                //Отключаем стандартное поведение формы
                e.preventDefault();

                //Включаем индикатор загрузки на кнопке
                const submitBtn = this.querySelector('.form-submit');
                submitBtn.classList.add('sended');

                //И отправляем запрос на сервер
                const formData = new FormData(el);
                const request = new XMLHttpRequest();

                request.open('POST', action);
                request.send(formData);

                request.addEventListener('load', () => {
                    if (request.status === 200) {
                        const successModal = document.querySelector('.popup-success');
                        const popup = document.querySelector('[data-popup="consult"]');

                        
                        //После отправки формы
                        setTimeout(()=>{
                            //Очищаем форму и отключаем кнопку отправки формы
                            el.reset();
                            submitBtns.forEach(el=> {
                                el.disabled = true;
                            });

                            //Если форма была отправлена из всплывающего окна, закрываем его
                            if(popup.classList.contains('show')) popup.classList.remove('show');
                            //Показываем уведомление об успешной отправке
                            successModal.classList.add('show');
                            //Выключаем индикатор загрузки на кнопке
                            submitBtn.classList.remove('sended');
                        }, 1000);
                        
                        setTimeout(()=> {
                            //Скрываем уведомление об успешной отправке через N секунд
                            successModal.classList.remove('show');
                        }, 7000);
                    } else {
                        alert('Что-то пошло не так, попробуйте снова или проверьте подключение к интернету или перезагрузить страницу.');
                    }
                });
            });
        });
    }
}

export default forms;