function anim() {
    /*===========
    Standard animation of elements when scrolling
    ===========*/
    const anim = document.querySelectorAll('[data-anim]');
    
    function animToScroll(el) {
        const observerAnim = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    let time = entry.target.getAttribute('data-time');

                    if(!time) {
                        entry.target.classList.add('anim');
                    } else {
                        setTimeout(()=> {
                            entry.target.classList.add('anim');
                        }, time);
                    }
                }
            });
        }, {threshold: [.4]});
        observerAnim.observe(el);
    }

    if(anim.length > 0) anim.forEach(el=> {animToScroll(el)});
}

export default anim;